import React from 'react'

import Header from '../Components/Header'
import SectionTitleContainer from "../Components/Section/SectionTitleContainer"
import CaseStudies from '../Components/CaseStudy/CaseStudies'

class Experience extends React.Component {
  render() {
    return(
      <div>
        <Header
        title="Michael McMillan,"
        jobTitle="UX Leader"
        subtitle="People-focused UX leader and strategist, producing elegant designs to meet business objectives, and create thoughtful experiences and solutions."
        />
        <section>
          <div className="container">
          <SectionTitleContainer
                title="Overview"
                content={[
                  <div key="4">
                    <ul className="header-list" key="header-list-1">
                      <li><b>Design Thinking</b> – Problem-solving through discovery and creative approach to identify quick wins and recognize failure fast to define best in class products and design.</li>
                      <li><b>Innovative Solutions</b> – As part of leadership, I tell stories and create designs that help people fall in love with a product by focusing on meaningful experiences that drive engagement and creates stickiness.</li>
                      <li><b>Team Leadership</b> – Leading by example, creating accountability, and mentoring a multidisciplinary team of 50+ designers and consultants in a fully remote environment including teams within the US and India.</li>
                    </ul>
                  </div>
                ]}
            />
            <SectionTitleContainer
              title="Experience"
              content={[
              <div key="1">
                <div className="experience-company">
                  <h4>West Cary Group</h4>
                  <div className="experience-group">
                    <p>Senior Creative Director, that plays a pivotal role in shaping and leading our user experience (UX) and user interface (UI) design strategy across various digital platforms. Responsible for crafting visually stunning and intuitive digital experiences that captivate users and elevate our clients' brands to new heights.</p>
                    <h5 className="experience-title">Senior Creative Director, UX/UI</h5>
                    <p className="experience-date">July 2023 - Present</p>
                    <ul className="about-ordered-list">
                      <li>Led the design and launch of innovative digital products focused on enhancing global user experiences, particularly for clients in the payments and financial services sector.</li>
                      <li>Developed and implemented comprehensive design systems that improved design efficiency by 20% across multiple platforms, ensuring a cohesive user experience.</li>
                      <li>Collaborated cross-functionally with global teams in an Agile environment, driving the successful launch of products that exceeded revenue goals by 15%.</li>
                      <li>Spearheaded the design strategy for a high-impact client projects, integrating payments platforms that supported seamless financial transactions for millions of users.</li>
                      <li>Develop and articulate a clear and compelling UX/UI strategy for each client project, aligning it with their business objectives and target audience.</li>
                      <li>Product design work for leading financial company included design system integration and work.</li>
                      <li>Lead the creation of user personas, journey maps, and wireframes to guide the design process effectively.</li>
                      <li>Provide hands-on leadership, overseeing and guiding the creative team in crafting cutting-edge and user-centric design solutions across multiple platforms (web, mobile, and other digital touchpoints).</li>
                      <li>Inspire and mentor designers, fostering a collaborative and high-performing creative culture.</li>
                      <li>Collaborate closely with clients to understand their unique challenges, goals, and brand identity, translating their requirements through compelling storytelling and UX/UI concepts.</li>
                      <li>Present and articulate design concepts, rationale, and strategic thinking to clients with confidence and persuasion.</li>
                      <li>Collaborate seamlessly with project managers, developers, content strategists, and other stakeholders to ensure seamless project delivery and integration of UX/UI design solutions.</li>
                      <li>Champion a user-centric approach, collaborating with UX researchers to gather insights and data that inform design decisions.</li>
                    </ul>
                  </div>
                </div>
                <div className="experience-company">
                  <h4>Ansira</h4>
                  <div className="experience-group">
                    <p>Experienced, UX, problem solver and strategic decision-maker focused on creating a cohesive team to drive product growth, deliver efficient output, and provide a holistic view of the business from first point of contact through final communications and product delivery.</p>
                    <h5 className="experience-title">Senior Director, User Experience & Product Design</h5>
                    <p className="experience-date">May 2022 - July 2023</p>
                    <ul className="about-ordered-list">
                      <li>Establish global UX processes, procedures, design systems and infrastructure to support rapid design activities that integrate into Agile development sprint cycles aligning business direction and support product managers and development teams.</li>
                      <li>Set overall design org's annual budget, priorities and planning with a focus on both business growth and team growth.</li>
                      <li>As a strategic leader, present UX and creative work to clients in pitch meetings demonstrating our design capabilities to produce deliverables such as roadmaps, heuristic and competitive analyses, primary and secondary research and the creation of personas, site maps, future-state assessments and responsive wireframes for web, mobile, and tablet-based projects and products, with occasional physical space crossover.</li>
                      <li>Partner with our strategy team, product owners, and engineering teams to provide strategic direction, create cohesive design outcomes, influence execution and drive product adoption for internal teams and clients.</li>
                      <li>Manage a global design team that is laser focused on the people that uses the product, challenging the team to be innovative, collaborative and willing to share feedback to accomplish that goal.</li>
                      <li>Mentor and coach individual contributors as well as director and manager level associates to create a high-performance culture that is clear in focus and rallied around a common goal allowing for a 4% reduction in headcount without causing priorities to be missed.</li>
                      <li>Direct UX research for internal and client projects including workshopping, research design, stakeholder analysis, participant recruiting, interviewing/prototype testing, user feedback surveys, data collection and analysis, and presentation to stakeholders and senior leadership.</li>
                      <li>Successful in coordinating of the launch of a massive client project include: websites (ecommerce, traditional), loyalty program, and a design system for a major entertainment client focusing on revenue lift of 25% from UX enhancements and strategic direction.</li>
                    </ul>
                  </div>
                </div>
                <div className="experience-company">
                  <h4>Sincro, An Ansira Company</h4>
                  <div className="experience-group">
                    <h5 className="experience-title">Director, User Experience Design, Product Design & Client Onboarding</h5>
                    <p className="experience-date">August 2021 - May 2022</p>
                    <ul className="about-ordered-list">
                      <li>Led a team of 25+ designers and consultants to deliver exceptional client experience, over 5000 clients through user research, insights, and design thinking. </li>
                      <li>Worked with finance to forecast annual business unit budget, set priorities by vetting vendor relationships, and negotiate software contracts utilized within my org. </li>
                      <li>Facilitated design sprints to assess new product viability and market share impact of an MVP, leading to the inclusion of new product growth solutions and roadmap priorities. </li>
                      <li>Prioritized the adoption of SUS scoring for product elements to validate the effectiveness of feature releases show a growth of 20% in product adoption.</li>
                      <li>As part of our continuous improvement planning, I prioritized a quarterly program where the team reassess all of our processes to identify roadblocks, and improve efficiencies, which allowed us to reduce overall GTM cycle time below 30 days increase year end revenues by 5%.</li>
                    </ul>
                  </div>
                  <div className="experience-group">
                    <h5 className="experience-title">Creative Director, Design Team Manager</h5>
                    <p className="experience-date">August 2020 - August 2021</p>
                    <ul className="about-ordered-list">
                      <li>Successfully shifted internal organization mentality from selling features to helping clients tell their stories and share their experiences leveraging design thinking to unlock different approaches to attract and retain clients.</li>
                      <li>Defined the annual team strategic direction, goal planning and provided budget management for team growth opportunities.</li>
                      <li>Led the development of a centralized product design system library for use by multiple internal teams to streamline all product resources.</li>
                      <li>Developed wireframing for the design rebranding of the Sincro brand and digital experience, inclusive of all brand identity assets including the most recent Sincro and Ansira websites.</li>
                      <li>Provided leadership, program management and planning of a pre-sales design support program that is scalable and consistent to target and win new businesses by providing UX audits and heuristic evaluations.</li>
                      <li>Created developer ready wireframes across multiple device types (web, mobile, native) in an omni channel environment to deliver in-market concepts for testing and delivery of high-quality UI for a range of clients.</li>
                    </ul>
                  </div>
                </div>
                <div className="experience-company">
                  <h4>CDK Global</h4>
                  <div className="experience-group">
                    <p>Led and mentored the design team focused on tailoring digital experience for the automotive industry.</p>
                    <h5 className="experience-title">Creative Director, Design Team Manager</h5>
                    <p className="experience-date">June 2019 - August 2020</p>
                    <ul className="about-ordered-list">
                      <li>Creative thinker, managing 10+ design teams in the digital marketing arena.</li>
                      <li>Responsible for the generation of modules, wireframes, and product-based solutions aligned to user needs and drive more engagement and retention of user base.</li>
                      <li>Worked with finance to create capacity modeling, headcount forecasting and annual budget proposals for all design teams to establish baseline efficiencies, KPIs, and to fortify team value to the business.</li>
                      <li>Fostered creativity and increased design excellence by being a change agent, collaborator, and challenging the teams to consider the user's need first, then design to delight.</li>
                      <li>Decreased QA challenges by developing a shared resource program where US team train and mentor India associates which increased overall efficiency with time zones.</li>
                    </ul>
                  </div>
                </div>
                <div className="experience-company">
                  <h4>eLocal.com</h4>
                  <div className="experience-group">
                    <p>Transformed and grew the design presence for a 10-year-old performance-based advertising company whose mission is to connect consumers with local businesses.</p>
                    <h5 className="experience-title">User Experience Consultant</h5>
                    <p className="experience-date">November 2017 - June 2019</p>
                    <p>After a private equity purchase, I showed my adaptability by changing my role from a full-time employee to a consultant. This change allowed me to focus on the product design aspect of the internal product offerings.<br/></p>
                  </div>
                  <div className="experience-group">
                    <h5 className="experience-title">Creative Director & UX Director</h5>
                    <p className="experience-date">September 2010 - November 2017</p>
                    <p>Led design direction and co-collaborated on strategic direction for eLocal’s presence across native app, web, mobile and social media platforms.</p>
                    <ul className="about-ordered-list">
                      <li>Led UX and visual design direction and collaborated on strategic direction for eLocal's presence across native app, web, mobile and social media platforms.</li>
                      <li>Led the UX design team responsible for the experience strategy and design of B2B and B2C responsive web applications, and iOS/Android native app helping to drive the sale of the business.</li>
                      <li>Conduct usability studies including user sessions, and multivariate testing via the VWO platform to test landing page hypotheses increasing conversion rates by 5%.</li>
                      <li>Drove user research and synthesis for multiple products: created personas, user journeys, and scenarios to create clickable prototypes for testing, led remote and in-person test sessions and advocate for changes based on test findings. Increasing billable lead and call conversions by 25% compared to previous designs.</li>
                      <li>Defined and created all UI style guide, pattern library and design principles for use by designers, front-end web, and web application developers across all eLocal products and offerings.</li>
                      <li>Developed a continuous improvement program that created reusable SASS mixin library and a pattern design library reducing an estimated 5% in overall team development time.</li>
                    </ul>
                  </div>
                </div>
                <div className="experience-company">
                  <h4>Trend Source, INC</h4>
                  <div className="experience-group">
                    <p>Primary objective to maintain overall client vision and objectives thru strong design concepts and leveraging my relationship building skills.</p>
                    <h5 className="experience-title">Principal, Experience Design</h5>
                    <p className="experience-date">October 2007 – May 2019</p>
                    <p>After a private equity purchase, I changed my role from a full-time employee to a consultant’s role. This change allowed me to solely focus on the product design aspect of the internal product offerings as an IC.<br/></p>
                    <ul className="about-ordered-list">
                      <li>Visual, UX, and UI front-end developer for One Accord Digital (Transform), completed a large project for Kodak on-time and on budget which secured One Accord Digital as agency of choice for 2015.</li>
                      <li>Led the UX research, design and UI development of Dream Dinners', Loving with Food e-commerce website for the launch of their first direct to consumer food product.</li>
                      <li>Conducted stakeholder and user interviews, led test plan development, formulated and analyzed survey results, and implemented usability testing for prototype for client projects.</li>
                    </ul>
                  </div>
                </div>
              </div>
              ]}
            />
          </div>
          <CaseStudies />
        </section>
      </div>
    )
  }
}

export default Experience