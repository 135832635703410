import React from 'react'

import CaseStudies from '../../Components/CaseStudy/CaseStudies'
import CaseStudyHeader from '../../Components/CaseStudy/CaseStudyHeader'
import CaseStudySection from '../../Components/CaseStudy/CaseStudySection'

class DaveBustersUX extends React.Component {
  render() {
    const content =[
      { title: "Objective",
        text:<div className="col-sm-10 offset-sm-1">
              <h5>Goal</h5>
              <p>Design, test and develope a rebranded Dave & Busters experience that included an omni-channel approach: lead gen, bookings, e-commerce, loyalty, and instore.</p>
              <h5>Problem</h5>
              <p>The Dave and Busters team wanted a tested experience that would help drive consumers to their locations through an omni-channel approach.</p>
              <h5>My Role</h5>
              <p><span>Senior Director of UX</span>As the design leader for this project, I was responsible for all deliverables, research planning and testing, design system development, SEO planning, content strategy, and visual design.</p>
            </div>},
      { title: "Discovery",
        text:<div className="col-sm-10 offset-sm-1">
                <h5>Personas, Heuristic Evaluations, User Tasks.</h5>
                <p className='text-center'><img alt="Dave and Busters discovery" src={'../../images/caseStudies/DB-discovery.png'}></img></p>
              </div>
      },
      { title: "Users & Mapping",
        text:<div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <p>Onine user journey, and booking flows.</p>
                  </div>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Temp Title" src={'../../images/caseStudies/DB-flows.png'}></img></p>
                  </div>
                </div>
              </div>
      },
      { title: "Prototyping & User Testing",
        text:<div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <p>Figma wireframes were created for user testing and prototypes. We leveraged remote testing via UserZoomGo and heatmapping via HotJar.</p>
                  </div>
                </div>
                <div className='row align-items-start'>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Temp Title" src={'../../images/caseStudies/DB-proto.png'}></img></p>
                  </div>
                  <div className="col-sm-10 offset-sm-1">
                    <p>Two rounds of user testing provided some hypothesis validation, and visual direction.</p>
                  </div>
                </div>
              </div>
      },
      { title: "Ideation & Design System",
        text:<div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <p>With and omni-channel approach, we created a design system to ensure brand compliance across multiple channels. Our team uses the approach for most of our clients as it provides a more efficent user of time for downstream teams and provides a usable deliverable to our clients.</p>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Temp Title" src={'../../images/caseStudies/DB-designsystem.png'}></img></p>
                  </div>
                </div>
              </div>
      }
    ]
    return (
      <section>
        <article className="container">
          <div className="row">
            <CaseStudyHeader
              CsTitle = "Project: Dave & Busters UX"
              CsSubtitle = "Improved UX, visual design, and lead generation."
              CsHero = {'../../images/caseStudies/DB-Hero.png'}
            />
            <CaseStudySection content={content} />
            <CaseStudies />
          </div>
        </article>
      </section>
    )
  }
}

export default DaveBustersUX



