import React from 'react'

import CaseStudies from '../../Components/CaseStudy/CaseStudies'
import CaseStudyHeader from '../../Components/CaseStudy/CaseStudyHeader'
import CaseStudySection from '../../Components/CaseStudy/CaseStudySection'

class CocaColaAnd extends React.Component {
  render() {
    const content =[
      { title: "Objective",
        text:<div className="col-sm-10 offset-sm-1">
              <h5>Goal</h5>
              <p>Create an exceptional consumer experience that isn't just your everyday recipe "listing" experience.</p>
              <h5>Problem</h5>
              <p>On a very tight timeline, this project needed to be designed, tested and ship within a three month window, so it could be used at the SOBE Food & Wine Festival.  The site was designed for a mobile device, knowing that the users would be carrying their phones around the festival and to leverage kiosks and QR code stations.</p>
              <h5>My Role</h5>
              <p><span>Senior Director of UX</span>My role was both a leadership role and hands on keyboard contributor. I worked closely with the strategy team to work through intial concepts, journey map, user flows. In addition, I provided creative direction for the visual design based on wireframes and journey expectations. </p>
            </div>},
      { title: "Discovery",
        text:<div className="col-sm-10 offset-sm-1">
                <h5>Competitive Analysis – Who is doing something similar?</h5>
                <p>We analyzed trends, and reviewed 20+ recipe sites and mixing sites to identify expectations and current user journeys.</p>
                <h5>User Journey – What's the expectation?</h5>
                <p>There are many recipe sites available. How could a unique experience be created that leverages the Coca-Cola brand equity along with a fun way to find drinks.</p>
                <div className="col-sm-10 offset-sm-1">
                  <p className='text-center'><img alt="Coca-ColaAnd Discovery and Competitve analysis" src={'../../images/caseStudies/cokeAnd-Discovery.png'}></img></p>
                </div>
              </div>
      },
      { title: "Flow & Users",
        text:<div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <p></p>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <p></p>
                  </div>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Coca-ColaAnd Discovery and User Flows" src={'../../images/caseStudies/cokeAndSitemapConsumerFlow.jpg'}></img></p>
                  </div>
                </div>
              </div>
      },
      { title: "Ideation & Wireframing",
        text:<div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <p>Myself and our Creative Director sat down between client meetings to quickly hash out an approach that would be user friendly and beautiful.</p>
                  </div>
                </div>
                <div className='row align-items-start'>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Coca-Cola And Wireframes" src={'../../images/caseStudies/cokeAnd-Wires.png'}></img></p>
                  </div>
                </div>
              </div>
      },
      { title: "Prototyping",
        text:<div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <p>From wires, we build a more robust prototype in Adobe XD to obtain client buy-in and to sell the concept.</p>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className="col-sm-10 offset-sm-1">
                    <p className='text-center'><img alt="Coca-Cola And Prototype" src={'../../images/caseStudies/cokeAnd-Prototypes.png'}></img></p>
                  </div>
                </div>
              </div>
      },
      { title: "Closing the Loop",
        text: <div className='row align-items-center'>
                <div className="col-sm-10 offset-sm-1">
                  <h5>Was this successful?</h5>
                  <p>The projects MVP "<a href="https://mixology.coca-cola.com" target="_blank" rel="noreferrer">mixology.coca-cola.com</a> was released with 2 days to spare before the festival!</p>
                  <p><a href="https://www.linkedin.com/posts/rachel-cantlay_after-5-months-of-learning-collaborating-activity-7034375239757172736-F_f7" target="_blank" rel="noreferrer">The client</a> loved it! It was also featured in <a href="https://www.bhg.com/coca-cola-mixology-site-7255614" target="_blank" rel="noreferrer">Better Homes & Gardens</a> with a specific call out regarding the search functionality.</p>
                  <p>Post-festival survey feedback will help determine if the project was effective at grabbing attention and engaging users.</p>
                  <h5>What's next?</h5>
                  <p>Intenal facing site that includes recipe cards that can be created for sell-in opportunities.</p>
                  <p>Refinements to the navigation to allow stronger filtering of content, and a more robust fuzzy search.</p>
                  <p>Lead generation optimization and opportunities assessments.</p>
                  <p>The ability to add influencer generated content.</p>
                </div>
              </div>
      }
    ]
    return (
      <section>
        <article className="container">
          <div className="row">
            <CaseStudyHeader
              CsTitle = "Project: Coca-Cola&"
              CsSubtitle = "Mixology site for Coca-Cola."
              CsHero = {'../../images/caseStudies/CokeAnd-Hero-Desktop.png'}
            />
            <CaseStudySection content={content} />
            <CaseStudies />
          </div>
        </article>
      </section>
    )
  }
}

export default CocaColaAnd